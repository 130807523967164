<template>
  <div class="commits">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">{{ userInfo.username }} 的个人空间 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">{{ userInfo.username }} 的个人空间 </a>
      </div>
    </div>
    <div class="centers-el">
      <div class="left">
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="帖子管理" name="1">
            <div class="List-centers">
              <div class="List-item">
                <el-button type="warning" size="small" @click="navToPage('/Reply')">快去发帖 吸引粉丝吧！</el-button>
              </div>
              <div class="List-item" v-if="List.length==0">
                <div class="items-title">
                  <span>暂无发布记录</span>
                </div>
              </div>
              <div class="List-item" v-for="(val, index) in List" :key="index" @click="navToPage('/Receipt/' + val.id)">
                <el-image :src="baseUrls + val.image" lazy></el-image>
                <div class="items-title">
                  <span>{{ val.title }}</span>
                </div>
                <div class="createtime">
                  {{ val.createtime }}
                </div>
                <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(vals, index) in val.tag" :key="index">{{
                    vals
                  }}</el-tag>
               </div>
              </div>
            </div>
          </el-tab-pane>
          <!--
          <el-tab-pane label="电影管理" name="2">
            <div class="List-centers">
              <div class="List-item">
                <el-button type="warning" size="small" @click="navToPage('/PostMovie')">快去发布电影 吸引粉丝吧！</el-button>
              </div>
              <div class="List-item" v-if="List.length==0">
                <div class="items-title">
                  <span>暂无发布记录</span>
                </div>
              </div>
              <div class="List-item" v-for="(val, index) in List" :key="index" @click="navToPage('/Detail/' + val.id)">
                <el-image :src="baseUrls + val.image" lazy style="width: 128px;height:auto"></el-image>
                <div class="items-title">
                  <span>{{ val.title }}</span>
                </div>
                <div class="createtime">
                  {{ val.createtime }}
                </div>
                <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(vals, index) in val.tag" :key="index">{{
                    vals
                  }}</el-tag>
               </div>
              </div>
            </div>
          </el-tab-pane>-->
        </el-tabs>
        <div class="page-el">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
            :page-size="pageSize" layout="total,prev,pager,next" :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="right">
        <div class="hot_lists">
          <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
          <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChanges"></HotItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiCon from '../util/Api/index'
import HotItem from '@/components/HotSticks';
import axios from "axios"
export default {
  name: 'Reply',
  data() {
    return {
      List: [],
      activeName: '1',
      id: this.$route.params.id,
      token: this.$route.params.token,
      Lists: [],
      userInfo:{
        nickname:''
      },
 
      baseUrls: "https://www.sosunlive.com",
      content: '',
      category_ids: '',
      contents: '',
      val: '',
      total: 0,
      hotTotal: 0,
      hotList: [],
      currentPage: 1,  // 当前页码
      process: '',
      pageSize: 10,
    }
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store)
    this.ApiConUrl = this.ApiCon.getApiUrl()
  },
  created() {
    this.ApiCon = new ApiCon(this.$store)
    this.baseUrl = this.ApiCon.getApiUrl()
    this.init()
    let category_ids = localStorage.getItem("sticks_category_ids")
    if (category_ids) {
      this.category_ids = category_ids
    } else {
      this.category_ids = '30'
    }
  },
  components: {
    HotItem
  },
  methods: {
    backs: function () {
      //this.$router.push("/index");
      history.go(-1)
    },
    handleClick() {
      let self = this
      self.currentPage=1
      if(self.activeName=='1'){
        self.init()
      }else{
        self.inits()
      }
    },
    handleHotCurrentChanges(val) {
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$api.mall
        .getHotSticksList({
          page: val,
          type: this.category_ids,
          limit: this.pageSize,

        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getHotLists() {
      this.hotList = [];
      this.$api.mall
        .getHotSticksList({
          page: 1,
          type: this.category_ids,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    navToPage(val){
      let self = this;
      self.$router.push(val);
    },
    DelRow(val) {
      let self = this
      this.$confirm('此操作将删除该收藏, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.mall.delgleans({
          id: val.id,
          media_id: val.media_id
        }).then((res) => {
          if (res.code == 1) {
            let collects = res.data
            localStorage.setItem('collects_ids', collects)
            self.$message.success("取消成功");
            self.init()
          } else {
            self.$message.error("取消失败");
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleCurrentChange(val) {
      let self=this
      this.currentPage = val
      document.documentElement.scrollTop = 0
      if(self.activeName=='1'){
        self.init()
      }else{
        self.inits()
      }
    },
    inits() {
      this.List = []
      let self = this
      this.$api.mall.getFocusMediaList({
        page: this.currentPage,
        limit: this.pageSize,
        flist: self.id,
        flist_token:self.token
      }).then((ress) => {
        let res = ress.data
        for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].tag = res.data.data[i].tag.split(",");
        }
        this.List = res.data.data
        this.total = res.data.total
        //console.log(this.total)
        //this.getHotLists()
      }).catch(err => {
        // 报错
        //  console.log(err,"009999")
      })
    },
    init() {
      this.List = []
      let self = this
      this.$api.mall.getFocusSticksList({
        page: this.currentPage,
        limit: this.pageSize,
        flist: self.id,
        flist_token:self.token
      }).then((ress) => {
        let res = ress.data
        self.userInfo=res.data.data[0].user
        for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].tag = res.data.data[i].tag.split(",");
        }
        this.List = res.data.data
        this.total = res.data.total
       // console.log(this.total)
        this.getHotLists()
      }).catch(err => {
        // 报错
        //  console.log(err,"009999")
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 8000px) and (min-width: 1024px) {
  .commits {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .centers-el {
      width: 980px;
      overflow: hidden;
      margin: 48px auto auto auto;

      .left {
        width: 680px;
        overflow: hidden;
        float: left;
        box-sizing: border-box;

        .List-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          padding: 0px;
          box-sizing: border-box;

          .List-item {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 10px;
            box-sizing: border-box;
            cursor: pointer;
            border-top: 1px dashed #f0f2f7;
            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 8px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .createtime {
              width: 100%;
              overflow: hidden;
              color: #999;
              font-size: 12px;
              line-height: 28px;
              box-sizing: border-box;
              overflow: hidden;
            }

            .items-title {
              color: #37a;
              font-weight: 500;
              font-synthesis: style;
              font-size: 16px;
              cursor: pointer;
              width: 100%;
              margin: 10px auto;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            }

            .items-title span:hover {
              background: #37a;
              color: #fff;
            }
          }
        }
      }

      .right {
        width: 280px;
        overflow: hidden;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        float: right;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }
    }

    .page-el {
      width: 980px;
      overflow: hidden;
      margin: 10px auto;
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 980px;
        overflow: hidden;
        margin: 0px auto;

        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          padding: 1px;
          display: block;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .commits {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .centers-el {
      width: 99.99%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 48px auto auto auto;

      .left {
        width: 100%;
        overflow: hidden;
        margin: auto auto 10px auto;
        box-sizing: border-box;
        .List-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          padding: 0px;
          box-sizing: border-box;

          .List-item {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 10px;
            box-sizing: border-box;
            cursor: pointer;
            border-top: 1px dashed #f0f2f7;
            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 8px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .createtime {
              width: 100%;
              overflow: hidden;
              color: #999;
              font-size: 12px;
              line-height: 28px;
              box-sizing: border-box;
              overflow: hidden;
            }

            .items-title {
              color: #37a;
              font-weight: 500;
              font-synthesis: style;
              font-size: 16px;
              cursor: pointer;
              width: 100%;
              margin: 10px auto;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            }

            .items-title span:hover {
              background: #37a;
              color: #fff;
            }
          }
        }
      }

      .right {
        width: 100%;
        overflow: hidden;
        margin: 10px auto;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }

    }

    .page-el {
      width: 100%;
      overflow: hidden;
      margin: 10px auto;
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;
        .nav-items {
          text-decoration: none;
          padding: 1px;
          display: block;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
        }

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          margin-left: 10px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }

}
</style>